






















import { Component, Vue } from 'vue-property-decorator';
import NavBar from '@/components/NavBar.vue'; // @ is an alias to /src
import Footer from '@/components/Footer.vue'; // @ is an alias to /src

import axios from 'axios';


@Component({
    components: {
        Footer,
        NavBar,
      },
})

export default class Detail extends Vue {

    public path= "";
    public img=undefined;
    public obj={};
    data(){
        return {
            path:this.path,
            img:this.img,
            obj:this.obj,
        }
    }

    async mounted(){
window.scrollTo(0, 0)
        this.path = window.location.protocol + "//api." + window.location.hostname; // + "/" + getUrl.pathname.split('/')[1];
        const { data } = await axios.get(this.path+'/api/album-covers?populate=*', {});
        const imageUrls= data.data.map((a:any)=>a.attributes.pic.data.attributes.url);
        const uID=this.$route.params.id;
        const obj= data.data[uID].attributes;//.pic.data.attributes.url);
        console.error("OBJ:",obj)
        this.obj=obj;
        //this.img=imageUrls;
    }
}
