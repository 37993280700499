




















import { Component, Vue } from 'vue-property-decorator';
import NavBar from '@/components/NavBar.vue'; // @ is an alias to /src
import Footer from '@/components/Footer.vue'; // @ is an alias to /src
import axios from 'axios';


@Component({
    components: {
        Footer,
        NavBar,
      },
})

export default class Contact extends Vue {
   public text=null;

    data(){
        return {
            text:null,
        }
    }


    async created(){
console.error("mois");
        const path = window.location.protocol + "//api." + window.location.hostname; // + "/" + getUrl.pathname.split('/')[1];
        const { data } = await axios.get(path+'/api/email?populate=*', {});
        this.text=data.data.attributes.email;
    }
}
