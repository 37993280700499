



































import { Component, Vue } from 'vue-property-decorator';
import NavBar from '@/components/NavBar.vue'; // @ is an alias to /src
import Footer from '@/components/Footer.vue'; // @ is an alias to /src

import axios from 'axios';


@Component({
    components: {
        Footer,
        NavBar,
      },
})

export default class About extends Vue {

    public path= "";
    public img=undefined;
    data(){
        return {
            path:this.path,
            img:this.img,
        }
    }

    async created(){
        this.path = window.location.protocol + "//api." + window.location.hostname; // + "/" + getUrl.pathname.split('/')[1];
        const { data } = await axios.get(this.path+'/api/album-covers?populate=*', {});
        const imageUrls= data.data.map((a:any)=>a.attributes);
        //const imageUrls= data.data.map((a:any)=>a.attributes.pic.data.attributes);
        console.error("ablen", data);
        for(let i=0;i<imageUrls.length;i++){
        //for(let i=imageUrls.length-1;i>=0;i--){
            imageUrls[i]={path: imageUrls[i].pic.data.attributes.url, index:i, title:imageUrls[i].title};
        }
	imageUrls.reverse();
        this.img=imageUrls;
    }
}
